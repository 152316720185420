import { Vue, Component, Prop } from 'vue-property-decorator';
import { ConfirmDialogText } from '@/models/ConfirmDialogText';

@Component<ConfirmDialog>({
  components: {
    //
  },
})
export default class ConfirmDialog extends Vue {
  @Prop({default: false})
  protected showDialog!: boolean;

  @Prop()
  protected confirmDialogText!: ConfirmDialogText;

  protected confirm(confirmation: boolean) {
    this.$emit('confirmResponse', event, confirmation);
  }

}
