import RsaSign from 'jsrsasign';

export const sign = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    const oHeader = {
      alg: 'HS256',
      typ: 'JWT',
    };
    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(payload);
    const signToken = RsaSign.jws.JWS.sign('HS256', sHeader, sPayload, {rstr: process.env.VUE_APP_ACCESS_TOKEN});
    resolve(signToken);
  });
};
