import { Vue, Component, Watch } from 'vue-property-decorator';
import { navigationItems, NavigationItem } from '@/components/Navigation/navigationItems';
import { request } from '@/services/request';

@Component<Navigation>({
  components: {
    //
  },
})
export default class Navigation extends Vue {
  protected navItems: NavigationItem[] = [];
  protected profileImage: string | null = null;

  get userName(): string {
    return this.$store.state.user;
  }

  get userImage(): string {
    return this.$store.state.user;
  }

  get userType(): string {
    const userTypeMap: any = {
      admin: 'Admin',
      supercoach: 'Super coach',
      coach: 'Coach',
      participant: 'Deelnemer',
    };
    return userTypeMap[this.$store.state.userType];
  }

  protected mounted() {
    if (this.$store.state.userType) {
      this.navItems = navigationItems[this.$store.state.userType];
    }
    this.checkImage();
  }

  protected checkImage() {
    if (this.$store.state.userHasAvatar) {
      this.profileImage = `${process.env.VUE_APP_API_URL}/admin/v1/user/${this.$store.state.userId}/avatar`;
    }
  }

  protected navigateToProfile() {
    if (this.$route.path !== '/profiel') this.$router.push({ path: '/profiel', params: { type: 'admin' } });
  }

  @Watch('$store.state.userType', {deep: true})
  protected storeWatch(to: any) {
    this.navItems = navigationItems[this.$store.state.userType];
    this.checkImage();
  }
}
