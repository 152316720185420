import { Vue, Component, Prop } from 'vue-property-decorator';
import { request } from '@/services/request';

// services
import { checkPasswordStrength } from '@/services/passwordStrength';

@Component<ProfileAction>({
  components: {
    //
  },
})
export default class ProfileAction extends Vue {
  @Prop()
  protected bodyText?: string;
  protected currentPassword = '';
  protected newPassword = '';
  protected newPasswordConfirmation = '';
  protected errorMessage = '';
  protected validationErrorMessages = {
    length: 'Het wachtwoord moet minimaal 8 karakters bevatten',
    lowercase: 'Het wachtwoord mist een kleine letter',
    uppercase: 'Het wachtwoord mist een hoofdletter',
    numeric: 'Het wachtwoord mist een nummer',
    special: 'Het wachtwoord mist een speciaal karakter (!@#$%^&*)',
  };
  protected validationErrorKeys: string[] = [];
  protected passwordChangeSuccess = false;
  protected showPassword = false;
  protected showPasswordTwo = false;
  protected showPasswordThree = false;

  get allowSave() {
    if (this.newPassword.length && this.newPasswordConfirmation.length && this.currentPassword.length) return true;
    return false;
  }

  protected saveNewPassword() {
    request(
      'PUT',
      `${process.env.VUE_APP_API_URL}/global/v1/changePassword`,
      false,
      true,
      {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      },
      ).then(
      (response: any) => {
        console.log('response');
        this.passwordChangeSuccess = true;
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  protected validatePassword() {
    const failedArray = checkPasswordStrength(this.newPassword);

    if (this.newPassword !== this.newPasswordConfirmation) {
      this.errorMessage = 'Wachtwoorden komen niet overeen';
      return false;
    }
    this.errorMessage = '';

    if (failedArray.length) {
      this.validationErrorKeys = failedArray;
      return false;
    }

    this.validationErrorKeys = [];
    return true;
  }

  protected cancel() {
    this.$emit('cancel');
  }

  protected save() {
    if (!this.validatePassword()) {
      return;
    }

    this.saveNewPassword();
  }
}

