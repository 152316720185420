import { Vue, Component, Watch } from 'vue-property-decorator';

@Component<Breadcrumbs>({
  components: {
    //
  },
})
export default class Breadcrumbs extends Vue {
  protected breadcrumbList = [];

  protected mounted() {
    this.breadcrumbList = this.$route.meta?.breadcrumb;
  }

  protected updateBreadcrumbs() {
    this.breadcrumbList = this.$route.meta?.breadcrumb;
  }

  protected click(link: string) {
    if (!link) return;
    if (link === 'back') this.$router.go(-1);
    else this.$router.push(link);
  }

  @Watch('$route', {deep: true})
  protected routeWatch() {
    this.updateBreadcrumbs();
  }
}
