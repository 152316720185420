export class AddParticipantForm {
  public form = [
    {
      cols: '12',
      input: {
        name: 'myCoach',
        get: 'participant',
        type: 'autocomplete',
        label: 'Deelnemer',
        value: '',
        icon: 'mdi-account',
        items: [],
        required: true,
        validate: '',
        valid: true,
      },
    },
  ];

  public fillValues(values: any) {
    this.form.forEach((input: any) => {
      if (values[input.input.name]) {
        input.input.value = values[input.input.name];
      }
    });
  }
}
