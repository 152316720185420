import { Vue, Component, Prop, Watch} from 'vue-property-decorator';
import debounce from 'debounce';
import TextIconButton from '@/components/TextIconButton/TextIconButton.vue';
import { ActionType, TableHeader, TableRow } from '@/models/Datatable';
import moment from 'moment';

@Component<Datatable>({
  components: {
    TextIconButton,
  },
  filters: {
    formatDate: (date: any) => {
      if (!date) return  '';
      return moment.unix(date).format('DD-MM-YYYY HH:mm');
    },
    parseDayPart: (dayPart: 'morning' | 'afternoon' | 'evening'): DayPart | '-' => {
      if (!dayPart) return  '-';

      const labels: DayPartLabels = {
        morning: 'Ochtend',
        afternoon: 'Middag',
        evening: 'Avond',
      };

      return labels[dayPart] ? labels[dayPart] : '-';
    },
    formatReadiness: (readiness: any) => {
      if (!readiness) return  '-';
      if (readiness <= 25) return 'Heel laag';
      else if (readiness <= 50) return 'Laag';
      else if (readiness <= 75) return 'Normaal';
      else if (readiness > 75) return 'Hoog';
      return '-';
    },
  },
})
export default class Datatable extends Vue {
  @Prop()
  protected tableTitle?: string;

  @Prop()
  protected tableHeaders!: TableHeader[];

  @Prop()
  protected tableRows!: TableRow[];

  @Prop({default: false})
  protected disableSearch?: boolean;

  @Prop({ default: false })
  protected selectable?: boolean;

  @Prop({ default: () => {
    return [];
  } })
  protected iconButtons?: any;

  @Prop()
  protected selectFilters?: any;

  protected selected = [];
  protected search = '';
  protected tableActions: ActionType[] = [];
  protected debounceSearch: (query: string) => void = this.searchHandler();

  protected mounted() {
    this.setTableActions();
  }

  protected filterSelected(selected: any) {
    this.$emit('filterSelected', event);
  }

  protected clickedButton(event: Event, type: string) {
    this.$emit('dataTableButtonClick', event, {type});
  }

  protected setTableActions() {
    const actions = this.tableHeaders.find((header: TableHeader) => header.value === 'actions');
    if (!actions) return;
    if (actions.actionTypes) this.tableActions = actions.actionTypes;
  }

  protected actionHandler(event: Event, actionType: string, item: any) {
    this.$emit('actionClick', event, {actionType, ...item});
  }

  protected searchHandler() {
    return debounce((query: string) => {
      this.$emit('searchHandler', query);
    }, 500);
  }

  @Watch('tableHeaders', {deep: true})
  protected tableHeadersWatch(to: any) {
    this.setTableActions();
  }

  @Watch('selected')
  protected selectedWatch(to: any) {
    this.$emit('selectedChange', this.selected);
  }
}

type DayPartLabels = {
  morning: DayPart;
  afternoon: DayPart;
  evening: DayPart;
};

type DayPart = 'Ochtend' | 'Middag' | 'Avond';
