import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import FormInput from '@/components/FormInput/FormInput.vue';

@Component<FormDialog>({
  components: {
    FormInput,
  },
})
export default class FormDialog extends Vue {
  @Prop({default: false})
  protected showDialog!: boolean;

  @Prop()
  protected formInputs?: any;

  @Prop()
  protected dialogTitle!: string;

  @Prop()
  protected formText?: string;

  @Prop()
  protected dialogAction!: string;

  @Prop({default: false})
  protected isEditing?: boolean;

  @Prop({default: false})
  protected isCreating?: boolean;

  @Prop({default: false})
  protected responseIsLoading?: boolean;

  @Prop()
  protected formResponse?: any = null;

  protected showRequiredLabel = false;
  protected disableConfirm = false;
  protected completed = false;

  protected mounted() {
    this.disableConfirm = this.checkRequired();
    if (this.formInputs) {
      this.formInputs.some((input: any) => {
        if (input.input.required) {
          this.showRequiredLabel = true;
        }
      });
    }
  }

  protected checkRequired() {
    let disable = false;
    this.formInputs.forEach((item: any) => {
      if (item.allowedUserTypes && !item.allowedUserTypes.includes(this.userType)) return;
      if (item.input.required && !item.input.value) {
        if (item.create && !this.isCreating) return;
        disable = true;
      }
      if (item.input.required && !item.input.valid && item.input.validate) {
        disable = true;
      }
    });
    return disable;
  }

  get userType(): string {
    return this.$store.state.userType;
  }

  protected allowInput(input: any) {
    if (!this.isCreating && input.create) return false;
    if (input.allowedUserTypes && !input.allowedUserTypes.includes(this.userType)) return false;
    else return true;
  }

  protected confirm(confirmation: 'send' | 'close') {
    if (this.responseIsLoading) return;
    // (this.$refs.form as Vue & { reset: () => boolean }).reset();
    this.$emit('formResponse', confirmation, this.isEditing);
  }

  @Watch('formInputs', {deep: true})
  protected formInputsWatch(to: any) {
    this.disableConfirm = this.checkRequired();
  }

  @Watch('formResponse', {deep: true})
  protected formResponseWatch(to: any) {
    if (this.formResponse && this.formResponse.success) {
      this.completed = true;
    }
  }
}
