import { request } from '@/services/request';

export const getUsers = (userType: string, params: any, limit = 100) => {
  return new Promise((resolve, reject) => {
    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/users?limit=${limit}&page=1&type=${userType}`,
      false,
      false,
      params,
      ).then(
      (response: any) => {
        resolve(response);
      },
      (error: any) => {
        reject(error);
      },
    );
  });
};

export const getUser = (userId: string) => {
  return new Promise((resolve, reject) => {
    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/user?id=${userId}`,
      false,
      false,
      {},
      ).then(
      (response: any) => {
        resolve(response);
      },
      (error: any) => {
        reject(error);
      },
    );
  });
};

export const createOrPatchUser = (payload: any, patchId: string | null) => {
  return new Promise((resolve, reject) => {
    request(
      patchId ? 'PATCH' : 'POST',
      `${process.env.VUE_APP_API_URL}/admin/v1/user${patchId ? '/' + patchId : ''}`,
      false,
      false,
      payload,
      ).then(
      (response: any) => {
        resolve(response);
      },
      (error: any) => {
        reject(error);
      },
    );
  });
};

export const deleteUser = (userId: string) => {
  return new Promise((resolve, reject) => {
    request(
      'DELETE',
      `${process.env.VUE_APP_API_URL}/admin/v1/user/${userId}`,
      false,
      false,
      {},
      ).then(
      (response: any) => {
        resolve(response);
      },
      (error: any) => {
        reject(error);
      },
    );
  });
};
