// types
import { TableHeader, TableRow } from '@/models/Datatable';
import { Filter } from '@/models/Filter';

export const superCoachTable: TableItems = {
  rows: [],
  headers: [
    {
      text: 'Naam',
      value: 'fullName',
      align: 'start',
      sortable: false,
    },
    {
      text: 'Email',
      value: 'email',
      align: 'start',
      sortable: false,
    },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'view',
          icon: 'mdi-eye',
          label: 'Profielpagina bekijken',
        },
        {
          type: 'edit',
          icon: 'mdi-pencil',
          label: 'Aanpassen',
        }, {
          type: 'delete',
          icon: 'mdi-delete',
          label: 'Verwijderen',
        },
    ],
      sortable: false,
    },
  ],
};

export const detailCoachTable: TableItems = {
  rows: [],
  headers: [
    {
      text: 'Naam',
      align: 'start',
      sortable: false,
      value: 'fullName',
    },
    { text: 'Email', value: 'email' },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'view',
          icon: 'mdi-eye',
          label: 'Profielpagina bekijken',
        },
        {
          type: 'delete',
          icon: 'mdi-link-off',
          label: 'Coach ontkoppelen',
        },
    ],
      sortable: false,
    },
  ],
};

export const detailParticipantTable: TableItems = {
  rows: [],
  headers: [
    {
      text: 'Naam',
      align: 'start',
      sortable: false,
      value: 'fullName',
    },
    { text: 'Email', value: 'email' },
    { text: 'Bedrijf', value: 'company' },
    { text: 'Afdeling', value: 'department' },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'view',
          icon: 'mdi-eye',
          label: 'Profielpagina bekijken',
        },
        {
          type: 'delete',
          icon: 'mdi-link-off',
          label: 'Deelnemer ontkoppelen',
        },
    ],
      sortable: false,
    },
  ],
};

export const measurementTable: TableItems = {
  rows: [],
  headers: [
    {
      text: 'Datum en tijd',
      align: 'start',
      sortable: false,
      value: 'dateSpecifications.fullDate',
    },
    {
      text: 'Dagdeel',
      sortable: false,
      value: 'dayPart',
    },
    { text: 'Type', sortable: false, value: 'type' },
    { text: 'Minuten', sortable: false, value: 'duration' },
    { text: 'Energie', sortable: false, value: 'energy' },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'viewMeasurement',
          icon: 'mdi-eye',
          label: 'Meting bekijken',
        },
    ],
      sortable: false,
    },
  ],
  filters: [
    {
      name: 'type',
      label: 'Type meting',
      items: [
        {
          name: 'rest',
          label: 'Rust',
        },
        {
          name: 'work',
          label: 'Werk',
        },
      ],
      value: null,
    },
    {
      name: 'dayPart',
      label: 'Dagdeel',
      items: [
        {
          name: 'morning',
          label: 'Ochtend',
        },
        {
          name: 'afternoon',
          label: 'Middag',
        },
        {
          name: 'evening',
          label: 'Avond',
        },
      ],
      value: null,
    },
    {
      name: 'energy',
      label: 'Energie',
      items: [
        {
          name: 'verylow',
          label: 'Heel laag',
        },
        {
          name: 'low',
          label: 'Laag',
        },
        {
          name: 'normal',
          label: 'Normaal',
        },
        {
          name: 'high',
          label: 'Hoog',
        },
      ],
      value: null,
    },
  ],
};

export const tableHeaders: TableHeaders = {
  admin: [
    {
      text: 'Naam',
      value: 'fullName',
      align: 'start',
      sortable: false,
    },
    {
      text: 'Email',
      value: 'email',
      align: 'start',
      sortable: false,
    },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'view',
          icon: 'mdi-eye',
          label: 'Profielpagina bekijken',
        },
        // {
        //   type: 'edit',
        //   icon: 'mdi-pencil',
        //   label: 'Aanpassen',
        // },
    ],
      sortable: false,
    },
  ],
  supercoach: [
    {
      text: 'Naam',
      value: 'fullName',
      align: 'start',
      sortable: false,
    },
    {
      text: 'Email',
      value: 'email',
      align: 'start',
      sortable: false,
    },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'view',
          icon: 'mdi-eye',
          label: 'Profielpagina bekijken',
        },
        {
          type: 'edit',
          icon: 'mdi-pencil',
          label: 'Aanpassen',
        },
        {
          type: 'delete',
          icon: 'mdi-delete',
          label: 'Super coach verwijderen',
        },
    ],
      sortable: false,
    },
  ],
  coach: [
    {
      text: 'Naam',
      align: 'start',
      sortable: false,
      value: 'fullName',
    },
    {
      text: 'Email',
      value: 'email',
      sortable: false,
    },
    {
      text: 'Super coach',
      value: 'mySuperCoach',
      sortable: false,
    },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'view',
          icon: 'mdi-eye',
          label: 'Profielpagina bekijken',
        },
        {
          type: 'edit',
          icon: 'mdi-pencil',
          label: 'Aanpassen',
        },
        {
          type: 'delete',
          icon: 'mdi-delete',
          label: 'Coach verwijderen',
        },
    ],
      sortable: false,
    },
  ],
  participant: [
    {
      text: 'Naam',
      align: 'start',
      value: 'fullName',
      sortable: false,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: false,
    },
    {
      text: 'Bedrijf',
      value: 'company',
      sortable: false,
    },
    {
      text: 'Afdeling',
      value: 'department',
      sortable: false,
    },
    {
      text: 'Coach',
      value: 'myCoach',
      sortable: false,
    },
    {
      text: 'Acties',
      value: 'actions',
      actionTypes: [
        {
          type: 'view',
          icon: 'mdi-eye',
          label: 'Profielpagina bekijken',
        },
        {
          type: 'edit',
          icon: 'mdi-pencil',
          label: 'Aanpassen',
        },
        {
          type: 'delete',
          icon: 'mdi-delete',
          label: 'Deelnemer verwijderen',
        },
    ],
      sortable: false,
    },
  ],
};

type TableHeaders = {
  admin: TableHeader[];
  supercoach: TableHeader[];
  coach: TableHeader[];
  participant: TableHeader[];
};

type TableItems = {
  rows: TableRow[];
  headers: TableHeader[];
  filters?: Filter[];
};
