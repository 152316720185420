import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store/store';

import Login from '@/views/Login/Login.vue';
import ResetPassword from '@/views/ResetPassword/ResetPassword.vue';
import UsersOverview from '@/views/users/UsersOverview/UsersOverview.vue';
import UserDetail from '@/views/users/UserDetail/UserDetail.vue';
import Profile from '@/views/Profile/Profile.vue';
import MeasurementDetail from '@/views/Measurement/MeasurementDetail/MeasurementDetail.vue';
import Measurement from '@/views/Measurement/Measurement.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    meta: {
      public: true,
    },
    component: Login,
  },
  {
    path: '/wachtwoord-instellen',
    name: 'reset password',
    meta: {
      public: true,
    },
    component: ResetPassword,
  },
  {
    path: '/profiel',
    name: 'profiel',
    meta: {
      breadcrumb: [
        { name: 'Profiel' },
      ],
    },
    component: Profile,
  },
  {
    path: '/admins',
    name: 'admins',
    meta: {
      usersType: 'admin',
      breadcrumb: [
        { name: 'Admins' },
      ],
    },
    component: UsersOverview,
  },
  {
    path: '/admins/:id',
    name: 'admin detail',
    meta: {
      breadcrumb: [
        { name: 'Admins', link: '/admins' },
        { name: 'Detail' },
      ],
    },
    component: UserDetail,
  },
  {
    path: '/supercoaches',
    name: 'supercoaches',
    meta: {
      usersType: 'supercoach',
      breadcrumb: [
        { name: 'Supercoaches' },
      ],
    },
    component: UsersOverview,
  },
  {
    path: '/supercoaches/:id',
    name: 'supercoach detail',
    meta: {
      breadcrumb: [
        { name: 'Supercoaches', link: '/supercoaches' },
        { name: 'Detail' },
      ],
    },
    component: UserDetail,
  },
  {
    path: '/coaches',
    name: 'coaches',
    meta: {
      usersType: 'coach',
      breadcrumb: [
        { name: 'Coaches' },
      ],
    },
    component: UsersOverview,
  },
  {
    path: '/coaches/:id',
    name: 'coach detail',
    meta: {
      breadcrumb: [
        { name: 'Coaches', link: '/coaches' },
        { name: 'Detail' },
      ],
    },
    component: UserDetail,
  },
  {
    path: '/deelnemers',
    name: 'deelnemers',
    meta: {
      usersType: 'participant',
      breadcrumb: [
        { name: 'Deelnemers' },
      ],
    },
    component: UsersOverview,
  },
  {
    path: '/deelnemers/:id',
    name: 'deelnemer detail',
    meta: {
      breadcrumb: [
        { name: 'deelnemers', link: '/deelnemers' },
        { name: 'Detail' },
      ],
    },
    component: UserDetail,
  },
  {
    path: '/deelnemers/:id/meting/:measurementId',
    name: 'Meting',
    meta: {
      breadcrumb: [
        { name: 'deelnemers', link: '/deelnemers' },
        { name: 'Detail', link: 'back' },
        { name: 'Meting' },
      ],
    },
    component: MeasurementDetail,
  },
  {
    path: '/metingen',
    name: 'metingen',
    meta: {
      breadcrumb: [
        { name: 'Metingen' },
      ],
    },
    component: Measurement,
  },
  {
    path: '/metingen/:measurementId',
    name: 'meting detail',
    meta: {
      breadcrumb: [
        { name: 'Metingen', link: '/metingen' },
        { name: 'Meting detail' },
      ],
    },
    component: MeasurementDetail,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  if (!localStorage.getItem('userToken') && to.matched.some((route) => !route.meta.public)) {
    next({
      path: '/login',
    });
    return;
  } else {
    next();
  }

});

export default router;
