export const navigationItems: NavigationItems = {
  admin: [
    {
      label: 'Admins',
      to: '/admins',
      icon: 'mdi-account-multiple',
    },
    {
      label: 'Supercoaches',
      to: '/supercoaches',
      icon: 'mdi-account-multiple',
    },
    {
      label: 'Coaches',
      to: '/coaches',
      icon: 'mdi-account-multiple',
    },
    {
      label: 'Deelnemers',
      to: '/deelnemers',
      icon: 'mdi-account-multiple',
    },
    {
      label: 'Mijn metingen',
      to: '/metingen',
      icon: 'mdi-chart-bar',
    },
  ],
  supercoach: [
    {
      label: 'Coaches',
      to: '/coaches',
      icon: 'mdi-account-multiple',
    },
    {
      label: 'Deelnemers',
      to: '/deelnemers',
      icon: 'mdi-account-multiple',
    },
    {
      label: 'Mijn metingen',
      to: '/metingen',
      icon: 'mdi-chart-bar',
    },
  ],
  coach: [
    {
      label: 'Deelnemers',
      to: '/deelnemers',
      icon: 'mdi-account-multiple',
    },
    {
      label: 'Mijn metingen',
      to: '/metingen',
      icon: 'mdi-chart-bar',
    },
  ],
  participant: [
    {
      label: 'Metingen',
      to: '/metingen',
      icon: 'mdi-chart-bar',
    },
  ],
};

type NavigationItems = {
  [key: string]: NavigationItem[];
};

export type NavigationItem = {
  label: string;
  to: string;
  icon: string;
};
