import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getUsers } from '@/services/userRequests';
import debounce from 'debounce';

@Component<FormInput>({
  components: {
    //
  },
})
export default class FormInput extends Vue {
  @Prop()
  protected input!: any;

  protected autoCompleteItems: any = [];
  protected debounceSearch: (query: string) => void = this.searchHandler();
  protected searching = false;
  protected rules: any = {
    name: (value: string) => {
      const pattern = /^[a-zA-Z0-9 \-]+$/;
      return pattern.test(value);
    },
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(value)) return true;
      else return 'Voer een geldig e-mailadres in';
    },
    number: (value: string) => {
      const pattern = /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
      if (pattern.test(value)) return true;
      else return 'Voer een geldig telefoonnummer in';
    },
  };

  protected mounted() {
    if (this.input.type === 'autocomplete') {
      this.listUsers();
    }
  }

  protected validate(validate: string, value: any) {
    if (this.rules[validate](value) === true) this.input.valid = true;
    else this.input.valid = false;
    return [this.rules[validate](value)];
  }

  protected handleKeyUp(query: string) {
    if (this.input.get !== 'participant') return;
    this.searching = true;
    this.debounceSearch(query);
  }

  protected searchHandler() {
    return debounce((query: any) => {
      this.listUsers(query.target.value);
    }, 500);
  }

  protected listUsers(query?: string) {
    const params: any = {};

    if (query) {
      params.search = query;
    }

    getUsers(this.input.get, params, 100)
    .then((response: any) => {
      this.searching = false;
      if (response.data.users) {
        this.autoCompleteItems = response.data.users;
      }
    })
    .catch((error: any) => {
      this.searching = false;
      console.log('ERROR', error);
    });
  }
}
