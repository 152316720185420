import { Vue, Component, Watch} from 'vue-property-decorator';
import ProfileDetails from '@/components/ProfileDetails/ProfileDetails.vue';
import { SuperCoachForm } from '@/constants/forms/SuperCoachForm';
import { CoachForm } from '@/constants/forms/CoachForm';
import { ParticipantForm } from '@/constants/forms/ParticipantForm';
import FormDialog from '@/components/dialogs/FormDialog/FormDialog.vue';
import UploadDialog from '@/components/dialogs/UploadDialog/UploadDialog.vue';
import ProfileAction from '@/components/ProfileAction/ProfileAction.vue';
import ChangePassword from '@/components/ChangePassword/ChangePassword.vue';
import { request } from '@/services/request';
import { createOrPatchUser } from '@/services/userRequests';

@Component<Profile>({
  components: {
    ProfileDetails,
    ProfileAction,
    ChangePassword,
    FormDialog,
    UploadDialog,
  },
})
export default class Profile extends Vue {
  protected user: any = null;
  protected showPasswordDialog = false;
  protected profileImage = require('@/assets/images/profile-avatar.jpeg');
  protected profileItems: any = [];
  protected formInputs: any = new CoachForm();
  protected formPrefill: any = {};
  protected showFormDialog = false;
  protected showUploadDialog = false;
  protected formResponseLoading = false;
  protected formResponse: any = null;

  protected mounted() {
    this.setUserForm();
    this.getUser();
    this.profileImage = `${process.env.VUE_APP_API_URL}/admin/v1/user/${this.$store.state.userId}/avatar`;
    this.setFormInputs(this.$store.state.userType);
    this.checkImage();
  }

  protected getUser() {
    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/user?id=${this.$store.state.userId}`,
      false,
      false,
      {},
      ).then(
      (response: any) => {
        if (response.data.user) {
          this.user = response.data.user;
          this.profileItems = [
            { text: `
              ${this.user.firstName}
              ${this.user.middleName ? this.user.middleName : ''}
              ${this.user.lastName}`, icon: 'mdi-account',
            },
            { text: this.user.email, icon: 'mdi-at' },
            { text: this.user.phone, icon: 'mdi-phone' },
          ];

          if (this.user.company) {
            this.profileItems.push(
              {
                text: `${this.user.company} ${this.user.department ? '- ' + this.user.department : ''}`,
                icon: 'mdi-city',
              },
            );
          }

          if (this.user.language) {
            this.profileItems.push(
              { text: this.user.language, icon: 'mdi-earth' },
            );
          }
        }
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  protected checkImage() {
    if (this.$store.state.userHasAvatar) {
      this.profileImage = `${process.env.VUE_APP_API_URL}/admin/v1/user/${this.$store.state.userId}/avatar`;
    } else {
      this.profileImage = require('@/assets/images/profile-avatar.jpeg');
    }
  }

  protected setFormInputs(formType: string) {
      switch (formType) {
        case 'supercoach':
          this.formInputs = new SuperCoachForm();
          break;
        case 'coach':
          this.formInputs = new CoachForm();
          break;
        case 'participant':
          this.formInputs = new ParticipantForm();
          break;
        default:
          break;
      }
    }

  protected clearEditCreateForm() {
    this.showFormDialog = false;
    this.setFormInputs(this.$store.state.userType);
    this.formResponse = null;
  }

  protected editUser(payload: any) {
    this.formResponseLoading = true;
    createOrPatchUser(payload, this.$store.state.userId)
    .then((response: any) => {
      console.log('response', response);
      this.formResponseLoading = false;
      this.clearEditCreateForm();
      this.getUser();
    })
    .catch((error: any) => {
      this.formResponseLoading = false;
      this.formResponse = {
        success: false,
        message: 'Er is iets fout gegaan',
      };
      console.log('ERROR', error);
    });
  }

  protected editFormResponse(confirmed: 'send' | 'close') {
    if (confirmed === 'close') {
      this.clearEditCreateForm();
      return;
    }
    const requestPayload: any = {};
    this.formInputs.form.forEach((input: any) => {
      if (!input.create) {
        requestPayload[input.input.name] = input.input.value;
      }
    });
    this.editUser(requestPayload);
  }

  protected setUserForm() {
    switch (this.$store.state.userType) {
      case 'supercoach':
        this.formInputs = new SuperCoachForm();
        break;
      case 'coach':
        this.formInputs = new CoachForm();
        break;
      case 'deelnemer':
        this.formInputs = new ParticipantForm();
        break;
      default:
        break;
    }
  }

  protected uploadDialogResponse(event: any, confirmation: boolean, formData: FormData) {
    if (!confirmation) {
      this.showUploadDialog = false;
      return;
    }

    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/admin/v1/user/avatar`,
      false,
      false,
      formData,
      ).then(
      (response: any) => {
        this.showUploadDialog = false;
        location.reload();
      },
      (error: any) => {
        console.log('error', error);
        this.showUploadDialog = false;
      },
    );
  }

  protected profileClickEvent(event: Event, eventType: string) {
    if (eventType === 'editProfile') {
      this.formPrefill = {
        firstName: this.user.firstName,
        middleName: this.user.middleName,
        lastName: this.user.lastName,
        email: this.user.email,
        phone: this.user.phone,
        language: this.user.language ? this.user.language : '',
      };

      if (this.user.company) {
        this.formPrefill.company = this.user.company;
        this.formPrefill.companySection = this.user.department;
      }

      this.formInputs.fillValues(this.formPrefill);
      this.showFormDialog = true;
    } else if (eventType === 'editImage') {
      this.showUploadDialog = true;
    }
  }

  @Watch('$store.state.userType', {deep: true})
  protected storeWatch(to: any) {
    this.setFormInputs(this.$store.state.userType);
    this.checkImage();
  }
}
