import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<TextIconButton>({
  components: {
    //
  },
})
export default class TextIconButton extends Vue {
  @Prop()
  protected buttonText!: string;

  @Prop()
  protected buttonIcon!: string;

  @Prop()
  protected disabled?: boolean;

  @Prop({default: 'primaryGreen'})
  protected buttonColor?: string;

  protected clicked() {
    this.$emit('clickedAdd');
  }
}
