import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    token: null,
    user: null,
    userId: null,
    userType: null,
    userHasAvatar: null,
  },
  mutations: {
    updateToken: (state, token) => {
      state.token = token;
    },
    updateUser: (state, user) => {
      state.user = user;
    },
    updateUserId: (state, userId) => {
      state.userId = userId;
    },
    updateUserType: (state, userType) => {
      state.userType = userType;
    },
    updateUserHasAvatar: (state, userHasAvatar) => {
      state.userHasAvatar = userHasAvatar;
    },
  },
  actions: {
    setToken: (context, token) => {
      context.commit('updateToken', token);
    },
    setUser: (context, user) => {
      context.commit('updateUser', user);
    },
    setUserId: (context, user) => {
      context.commit('updateUserId', user);
    },
    setUserType: (context, userType) => {
      context.commit('updateUserType', userType);
    },
    setUserHasAvatar: (context, userHasAvatar) => {
      context.commit('updateUserHasAvatar', userHasAvatar);
    },
  },
});

type RootState = {
  token: string | null;
  user: any;
  userId: string | null;
  userType: 'admin' | 'supercoach' | 'coach' | 'participant' | null;
  userHasAvatar: boolean | null;
};
