const passwordChecks: any = {
  length: (password: string) => {
    return password.length > 7;
  },
  uppercase: (password: string) => {
    return (/[A-Z]/.test(password));
  },
  lowercase: (password: string) => {
    return (/[a-z]/.test(password));
  },
  numeric: (password: string) => {
    return (/[0-9]/.test(password));
  },
  special: (password: string) => {
    return (/[!@#$%^&*]/.test(password));
  },
};

const passwordCheckKeys = [
  'length',
  'lowercase',
  'uppercase',
  'numeric',
  'special',
];

export const checkPasswordStrength = (password: string): string[] => {
  const failedChecks: string[] = [];

  passwordCheckKeys.forEach((key: string) => {
    if (!passwordChecks[key](password)) {
      failedChecks.push(key);
    }
  });

  return failedChecks;
};

