import { Vue, Component} from 'vue-property-decorator';
import moment from 'moment';

// services
import { getUsers, getUser, createOrPatchUser } from '@/services/userRequests';
import { request } from '@/services/request';

// settings
import { AdminForm } from '@/constants/forms/AdminForm';
import { SuperCoachForm } from '@/constants/forms/SuperCoachForm';
import { CoachForm } from '@/constants/forms/CoachForm';
import { ParticipantForm } from '@/constants/forms/ParticipantForm';
import { AssignSuperCoachForm } from '@/constants/forms/AssignSuperCoachForm';
import { AssignCoachToSuperCoachForm } from '@/constants/forms/AssignCoachToSuperCoachForm';
import { AssignCoachForm } from '@/constants/forms/AssignCoachForm';
import { AddParticipantForm } from '@/constants/forms/AddParticipantForm';
import { tableHeaders, detailCoachTable, detailParticipantTable, measurementTable } from '@/constants/tableItems';
import { chartData, chartFilterPeriodItems } from '@/constants/chart';

// UI elements
import ProfileDetails from '@/components/ProfileDetails/ProfileDetails.vue';
import ProfileAction from '@/components/ProfileAction/ProfileAction.vue';
import FormDialog from '@/components/dialogs/FormDialog/FormDialog.vue';
import ExportDialog from '@/components/dialogs/ExportDialog/ExportDialog.vue';
import Datatable from '@/components/Datatable/Datatable.vue';
import MeasurementChart from '@/components/charts/MeasurementChart/MeasurementChart';
import DatePicker from '@/components/DatePicker/DatePicker.vue';

// types
import { User } from '@/models/User';
import { TableHeader, TableButton } from '@/models/Datatable';
import { Filter, RequestFilter } from '@/models/Filter';

@Component<UserDetail>({
  components: {
    ProfileDetails,
    ProfileAction,
    FormDialog,
    Datatable,
    MeasurementChart,
    DatePicker,
    ExportDialog,
  },
})
export default class UserDetail extends Vue {
  // user
  protected userDetailId = '';
  protected userDetailType: 'admin' | 'supercoach' | 'coach' | 'participant' = 'participant';
  protected user: any = null;
  protected tabsMap = {
    admin: ['coach', 'participant'],
    supercoach: ['coach', 'participant'],
    coach: ['participant'],
    participant: ['measurementsList', 'measurementsChart'],
  };

  // profile
  protected profileImage = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSGwrtotkA9S4ziVjMJYT-NN7JDS-HVZKkAwc5fkDLkoGgiGpVd&usqp=CAU';
  protected profileItems: any = [];
  protected profileActionMap = {
    admin: ['assignCoach', 'assignParticipant'],
    supercoach: ['assignCoach', 'assignParticipant'],
    coach: ['editAssignedSuperCoach', 'assignParticipant'],
    participant: ['editAssignedCoach'],
  };
  protected profileActions: any = [];

  // form dialog
  protected showFormDialog = false;
  protected formDialogTitle = '';
  protected formDialogActionText = '';
  protected formDialogText = '';
  protected formDialogInputs: any = [];
  protected formResponseLoading = false;
  protected formResponse: any = null;
  protected currentFormType: any = '';

  // export dialog
  protected showExportDialog = false;

  // datatable
  protected tableFilters: Filter[] = [];
  protected activeFilters: RequestFilter[] = [];
  protected currentActionId = '';

  // datatable participants
  protected participantTableHeaders: TableHeader[] = detailParticipantTable.headers;
  protected participantTableRows: User[] = [];
  protected searchQueryParticipant = '';

  // datatable coaches
  protected coachTableHeaders: TableHeader[] = detailCoachTable.headers;
  protected coachTableRows: User[] = [];
  protected searchQueryCoach = '';

  // datatable measurements
  protected measurementTableHeaders: TableHeader[] = measurementTable.headers;
  protected measurementTableRows: User[] = [];
  protected tableButtons: TableButton[] = [
    // {
    //   text: 'Metingen exporteren',
    //   icon: 'mdi-download',
    //   action: 'export',
    // },
  ];

  // chart
  protected selectedChartFilterPeriod = 'day';
  protected chartFilterPeriodItems = chartFilterPeriodItems;
  protected chartData: any = chartData;
  protected startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
  protected endDate = moment(new Date()).format('YYYY-MM-DD');

  protected mounted() {
    this.userDetailId = this.$route.params.id;
    this.getCurrentUser();
  }

  protected getCurrentUser() {
    getUser(this.userDetailId)
    .then((response: any) => {
      this.user = response.data.user;
      this.setUserSettings();
    })
    .catch((error: any) => {
      console.log('error', error);
    });
  }

  protected editUser(payload: any, userId: string) {
    this.formResponseLoading = true;
    createOrPatchUser(payload, userId)
    .then((response: any) => {
      this.formResponseLoading = false;
      this.showFormDialog = false;
      this.getCurrentUser();
    })
    .catch((error: any) => {
      this.formResponseLoading = false;
      this.formResponse = {
        success: false,
        message: 'Er is iets fout gegaan',
      };
      console.log('ERROR', error);
    });
  }

  protected setUserSettings() {
    this.userDetailType = this.user.userRole;
    this.setProfileItems();
    this.setProfileActions();
    this.setTableFilters();
    this.getSubUsers();

    if (this.user.avatar) {
      this.profileImage = `${process.env.VUE_APP_API_URL}/admin/v1/user/${this.user._id}/avatar`;
    }
  }

  protected setTableFilters() {
    if (this.userDetailType !== 'participant') {
      this.tableFilters = [
        {
          name: 'company',
          label: 'Bedrijf',
          items: [],
          value: null,
        },
        {
          name: 'department',
          label: 'afdeling',
          items: [],
          value: null,
        },
      ];
      return;
    }

    if (measurementTable.filters) this.tableFilters = measurementTable.filters;
  }

  protected getSubUsers() {
    if (this.tabsMap[this.userDetailType].includes('participant')) {
      this.listUsers('participant');
    }

    if (this.tabsMap[this.userDetailType].includes('coach')) {
      this.listUsers('coach');
    }

    if (this.tabsMap[this.userDetailType].includes('measurementsList')) {
      this.getMeasurements();
    }

    if (this.tabsMap[this.userDetailType].includes('measurementsChart')) {
      this.getMeasurementsStatistics();
    }
  }

  protected profileClickEvent(event: Event, eventType: string) {
    if (eventType === 'editProfile') {
      this.setForm('user', true);
    }
  }

  protected formDialogResponse(formResponse: 'close' | 'send') {
    if (formResponse === 'close') {
      this.showFormDialog = false;
      this.formResponse = null;
      return;
    }
    const requestPayload: any = {};

    if (this.currentFormType === 'assignParticipant') {
      requestPayload.myCoach = this.user._id;
      this.editUser(requestPayload, this.formDialogInputs.form[0].input.value);
      return;
    }

    if (this.currentFormType === 'assignCoach') {
      requestPayload.mySuperCoach = this.user._id;
      this.editUser(requestPayload, this.formDialogInputs.form[0].input.value);
      return;
    }

    if (this.currentFormType === 'disconnectParticipant') {
      requestPayload.myCoach = this.formDialogInputs.form[0].input.value;
      this.editUser(requestPayload, this.currentActionId);
      return;
    }

    if (this.currentFormType === 'disconnectCoach') {
      requestPayload.mySuperCoach = this.formDialogInputs.form[0].input.value;
      this.editUser(requestPayload, this.currentActionId);
      return;
    }

    this.formDialogInputs.form.forEach((input: any) => {
      if (!input.create) {
        requestPayload[input.input.name] = input.input.value;
      }
    });
    this.editUser(requestPayload, this.user._id);
  }

  protected handleProfileAction(action: string) {
    this.setForm(action);
  }

  protected setForm(formType: string, prefill = false) {
    this.currentFormType = formType;
    this.setFormInputs(formType);
    if (prefill) this.preFillUserForm();

    this.setFormDialogText(formType);

    this.showFormDialog = true;
  }

  protected setFormDialogText(formType: string) {
    const dialogTexts: any = {
      user: {
        formDialogTitle: 'Gebruiker aanpassen',
        formDialogActionText: 'Opslaan',
      },
      editAssignedSuperCoach: {
        formDialogTitle: 'Super coach toewijzen aan coach',
        formDialogText: 'Deze coach wordt overgenomen door de toegewezen super coach.',
        formDialogActionText: 'Super coach toewijzen',
      },
      editAssignedCoach: {
        formDialogTitle: 'Toegewezen coach wijzigen',
        formDialogText: 'Deze deelnemer wordt overgenomen door de toegewezen coach.',
        formDialogActionText: 'Coach toewijzen',
      },
      assignParticipant: {
        formDialogTitle: 'Deelnemer toewijzen',
        formDialogActionText: 'Deelnemer toegewijzen',
      },
      assignCoach: {
        formDialogTitle: 'Coach toewijzen',
        formDialogActionText: 'Coach toewijzen',
      },
      disconnectCoach: {
        formDialogTitle: 'Coach ontkoppelen',
        formDialogActionText: 'Coach ontkoppelen',
        formDialogText: 'Weet u zeker dat u deze coach wilt ontkoppelen? U kunt een nieuwe super coach toewijzen aan deze coach.',
      },
      disconnectParticipant: {
        formDialogTitle: 'Deelnemer ontkoppelen',
        formDialogActionText: 'Deelnemer ontkoppelen',
        formDialogText: 'Weet u zeker dat u deze deelnemer wilt ontkoppelen? U kunt een nieuwe coach toewijzen aan deze deelnemer.',
      },
    };

    this.formDialogTitle = dialogTexts[formType].formDialogTitle;
    this.formDialogActionText = dialogTexts[formType].formDialogActionText;
    if (dialogTexts[formType].formDialogText) this.formDialogText = dialogTexts[formType].formDialogText;
    else this.formDialogText = '';
  }

  protected preFillUserForm() {
    const formPrefill: any = {
      firstName: this.user.firstName,
      middleName: this.user.middleName,
      lastName: this.user.lastName,
      email: this.user.email,
      phone: this.user.phone,
    };

    if (this.userDetailType === 'participant') {
      formPrefill.company = this.user.company;
      formPrefill.department = this.user.department;
    }

    if (this.user.language) {
      formPrefill.language = this.user.language ? this.user.language : '';
    }

    this.formDialogInputs.fillValues(formPrefill);
  }

  protected setFormInputs(formType: string) {
    if (formType === 'user') {
      switch (this.userDetailType) {
        case 'admin':
          this.formDialogInputs = new AdminForm();
          break;
        case 'supercoach':
          this.formDialogInputs = new SuperCoachForm();
          break;
        case 'coach':
          this.formDialogInputs = new CoachForm();
          break;
        case 'participant':
          this.formDialogInputs = new ParticipantForm();
          break;
        default:
          break;
      }
      return;
    }

    switch (formType) {
      case 'editAssignedSuperCoach':
        this.formDialogInputs = new AssignSuperCoachForm();
        break;
      case 'editAssignedCoach':
        this.formDialogInputs = new AssignCoachForm();
        break;
      case 'assignParticipant':
        this.formDialogInputs = new AddParticipantForm();
        break;
      case 'assignCoach':
        this.formDialogInputs = new AssignCoachToSuperCoachForm();
        break;
      case 'disconnectCoach':
        this.formDialogInputs = new AssignSuperCoachForm();
        break;
      case 'disconnectParticipant':
        this.formDialogInputs = new AssignCoachForm();
        break;
      default:
        break;
    }
  }

  protected setProfileActions() {
    const possibleActions: any = {
      editAssignedSuperCoach: {
        headerText: 'Toegewezen Super coach wijzigen',
        tooltipText: 'Toegewezen Super coach wijzigen',
        bodyText: (this.user && this.user.mySuperCoach.name) ? this.user.mySuperCoach.name : '-',
        action: 'editAssignedSuperCoach',
      },
      editAssignedCoach: {
        headerText: 'Toegewezen coach wijzigen',
        tooltipText: 'Toegewezen coach wijzigen',
        bodyText: (this.user && this.user.myCoach.name) ? this.user.myCoach.name : '-',
        action: 'editAssignedCoach',
      },
      assignCoach: {
        headerText: 'Coach toewijzen',
        tooltipText: 'Een coach toewijzen aan deze super coach',
        icon: 'mdi-link-plus',
        action: 'assignCoach',
      },
      assignParticipant: {
        headerText: 'Deelnemer toewijzen',
        tooltipText: 'Een deelnemer toewijzen aan deze coach',
        icon: 'mdi-link-plus',
        action: 'assignParticipant',
      },
    };

    this.profileActions = [];
    this.profileActionMap[this.userDetailType].forEach((userAction: any) => {
      this.profileActions.push(possibleActions[userAction]);
    });
  }

  protected setProfileItems() {
    this.profileItems = [
      {
      text: `${this.user.fullName ? this.user.fullName : ''}`,
      icon: 'mdi-account',
      },
      {
        text: this.user.email ? this.user.email : '',
        icon: 'mdi-at',
      },
      {
        text: this.user.phone ? this.user.phone : '',
        icon: 'mdi-phone',
      },
    ];

    if (this.user.company) {
      this.profileItems.push(
        {
          text: `${this.user.company} ${this.user.department ? '- ' + this.user.department : ''}`,
          icon: 'mdi-city',
        },
      );
    }

    if (this.user.language) {
      this.profileItems.push(
        {
          text: this.user.language,
          icon: 'mdi-earth',
        },
      );
    }
  }

  protected viewDetail(item: any) {
    if (item.userRole === 'coach') this.$router.push(`/coaches/${item._id}`);
    else if (item.userRole === 'participant') this.$router.push(`/deelnemers/${item._id}`);
  }

  protected viewMeasurementDetail(id: string, measurementType: string) {
    this.$router.push({ path: `/deelnemers/${this.userDetailId}/meting/${id}`, query: { measurementType } });
  }

  // datatable functions
  protected handleDatatableAction(event: Event, item: any) {
    if (item.actionType === 'view') this.viewDetail(item);
    else if (item.actionType === 'viewMeasurement') this.viewMeasurementDetail(item.measurementID, item.type);
    else if (item.actionType === 'delete') {
      this.currentActionId = item._id;
      if (item.userRole === 'participant') this.setForm('disconnectParticipant');
      else if (item.userRole === 'coach') this.setForm('disconnectCoach');
    }
  }

  protected handleDatatableButton(event: Event, response: any) {
    if (response.type === 'export') {
      this.showExportDialog = true;
    }
  }

  protected exportDialogResponse(dialogConfirmation: any) {
    this.showExportDialog = false;
    // if (!dialogConfirmation) this.showExportDialog = false;
  }

  protected filterSelected() {
    this.tableFilters.forEach((filter: Filter) => {
      if (filter.name === 'company') {

        // if selected company has subItems add them to department array
        if (filter.value && filter.value.subItems) {
          this.tableFilters[1].items = filter.value.subItems;
        } else {
          this.tableFilters[1].items = [];
        }

        // if selected company has no match with currently selected department, clear selected department
        if (this.tableFilters[1].value) {
          if (!this.tableFilters[1].items.includes(this.tableFilters[1].value)) {
            this.tableFilters[1].value = null;
          }
        }
      }
    });

    if (this.userDetailType === 'participant') {
      this.getMeasurements();
      return;
    }
    this.listUsers('participant', true);
  }

  protected searchParticipants(query: string) {
    this.searchQueryParticipant = query;
    this.listUsers('participant', true);
  }

  protected searchCoaches(query: string) {
    this.searchQueryCoach = query;
    this.listUsers('coach');
  }

  // fetch users based on userType and params
  protected listUsers(userType: string, allowFilter = false) {
    const params: RequestParams = {};

    this.activeFilters = [];
    this.tableFilters.forEach((filter: Filter) => {
      if (filter.value) {
        this.activeFilters.push(
          {
            name: filter.name,
            value: typeof filter.value === 'object' ? filter.value.name : filter.value,
          },
        );
      }
    });

    if (this.activeFilters && allowFilter) {
      this.activeFilters.forEach((filter: RequestFilter) => {
        params[filter.name] = filter.value;
      });
    }

    if (userType === 'participant' && this.searchQueryParticipant) {
      params.search = this.searchQueryParticipant;
    }

    if (userType === 'coach' && this.searchQueryCoach) {
      params.search = this.searchQueryCoach;
    }

    params.coachID = this.user._id,

    getUsers(userType, params)
    .then((response: any) => {
      if (response.data.users) {
        if (userType === 'participant') this.participantTableRows = response.data.users;
        if (userType === 'coach') this.coachTableRows = response.data.users;
      }

      if (response.data.companies) {
        response.data.companies.forEach((item: any) => {
          this.tableFilters[0].items.push({
            name: item.company,
            label: item.company,
            subItems: item.department.filter((department: string) => department),
          });
        });
      }
    })
    .catch((error: any) => {
      console.log('error', error);
    });
  }

  protected getMeasurements() {
    const params: any = {
      type: 'rest',
      page: 1,
      limit: 200,
    };

    this.activeFilters = [];
    this.tableFilters.forEach((filter: Filter) => {
      if (filter.value) {
        this.activeFilters.push(
          {
            name: filter.name,
            value: typeof filter.value === 'object' ? filter.value.name : filter.value,
          },
        );
      }
    });

    if (this.activeFilters) {
      this.activeFilters.forEach((filter: RequestFilter) => {
        params[filter.name] = filter.value;
      });
    }

    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/measurements/${this.userDetailId}`,
      false,
      false,
      params,
      ).then(
      (response: any) => {
        if (response.data.measurements) {
          this.measurementTableRows = response.data.measurements;
        }
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  protected getMeasurementsStatistics() {
    const params = {
      type: 'rest',
      startDate: moment(this.startDate).unix(),
      endDate: moment(this.endDate).add(1, 'days').unix(),
    };

    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/measurements/${this.userDetailId}/getStatistics`,
      false,
      false,
      params,
      ).then(
      (response: any) => {
        if (response.data.measurements) {
          this.chartData = response.data.measurements;
        }
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  protected setStartDate(date: string) {
    this.startDate = date;
    this.getMeasurementsStatistics();
  }

  protected setEndDate(date: string) {
    this.endDate = date;
    this.getMeasurementsStatistics();
  }

  get userType(): string {
    return this.$store.state.userType;
  }
}

type RequestParams = {
  [key: string]: string | number;
};
