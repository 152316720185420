import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<DatePicker>({
  components: {
    //
  },
})
export default class DatePicker extends Vue {
  @Prop()
  protected inputPlaceholder!: string;

  @Prop()
  protected date?: Date;

  protected pickerDate: any = null;
  protected menu = false;

  protected mounted() {
    this.pickerDate = this.date;
  }

  protected dateChanged() {
    this.$emit('dateChanged', this.pickerDate);
  }
}
