import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<ProfileAction>({
  components: {
    //
  },
})
export default class ProfileAction extends Vue {
  @Prop()
  protected headerText!: string;

  @Prop()
  protected tooltipText!: string;

  @Prop()
  protected bodyText?: string;

  @Prop()
  protected action!: string;

  @Prop({default: 'mdi-pencil'})
  protected icon?: string;

  protected clickEvent() {
    this.$emit('clickEvent', this.action);
  }
}

