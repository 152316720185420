import axios, { Method } from 'axios';
import { sign } from '@/services/JwtHelper';

export const request = async (method: Method, customUrl: string, encrypt = false, userToken = false, data?: any, token?: string) => {
  const userJwt: string | null|undefined = localStorage.getItem('userToken') || token;
  const axiosOptions: AxiosOptions = {
    method,
    url: customUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (encrypt && !userToken) {
    await sign(data).then((response) => {
      axiosOptions.headers.Authorization = `Bearer ${response}`;
    });
  } else if (!encrypt && userJwt) {
    axiosOptions.headers.Authorization = `Bearer ${userJwt}`;
  }

  if (data && !encrypt && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
    axiosOptions.data = data;
  }

  if (data && !encrypt && method === 'GET') {
    axiosOptions.params = data;
  }

  // console.log('axiosOptions---!', axiosOptions);
  return axios(axiosOptions);
};

type AxiosOptions = {
  method: Method;
  url: string;
  headers: any;
  data?: object;
  params?: object;
};

