import { Vue, Component } from 'vue-property-decorator';

// services
import { getUsers, createOrPatchUser, deleteUser } from '@/services/userRequests';
import { request } from '@/services/request';

// settings
import { tableHeaders } from '@/constants/tableItems';
import { SuperCoachForm } from '@/constants/forms/SuperCoachForm';
import { CoachForm } from '@/constants/forms/CoachForm';
import { ParticipantForm } from '@/constants/forms/ParticipantForm';

// UI elements
import Datatable from '@/components/Datatable/Datatable.vue';
import TextIconButton from '@/components/TextIconButton/TextIconButton.vue';
import FormDialog from '@/components/dialogs/FormDialog/FormDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog/ConfirmDialog.vue';
import ExportDialog from '@/components/dialogs/ExportDialog/ExportDialog.vue';

// types
import { User } from '@/models/User';
import { TableHeader } from '@/models/Datatable';
import { Filter, RequestFilter } from '@/models/Filter';
import { ConfirmDialogText } from '@/models/ConfirmDialogText';

@Component<UsersOverview>({
  components: {
    Datatable,
    TextIconButton,
    FormDialog,
    ConfirmDialog,
    ExportDialog,
  },
})
export default class UsersOverview extends Vue {
  protected userOverviewType: 'admin' | 'supercoach' | 'coach' | 'participant' = 'participant';
  protected editingId = '';

  // datatable
  protected userTypeDisplayName = {
    admin: {
      single: 'Admin',
      multiple: 'Admins',
    },
    supercoach: {
      single: 'Super Coach',
      multiple: 'Super coaches',
    },
    coach: {
      single: 'Coach',
      multiple: 'Coaches',
    },
    participant: {
      single: 'Deelnemer',
      multiple: 'Deelnemers',
    },
  };
  protected tableHeaders: TableHeader[] = tableHeaders[this.userOverviewType];
  protected tableRows: User[] = [];
  protected searchQuery = '';
  protected tableFilters: Filter[] = [];
  protected activeFilters: RequestFilter[] = [];

  // confirm dialog
  protected confirmDialog = false;
  protected confirmDialogText: ConfirmDialogText = {
    title: 'Confirm',
    body: 'Are you sure?',
    confirm: 'Confirm',
  };

  // form dialog
  protected formDialog = false;
  protected form: CoachForm | CoachForm | ParticipantForm | null = null;
  protected isEditing = false;
  protected formResponseLoading = false;
  protected formResponse: FormResponse | null = null;

  // export dialog
  protected allowedExport = ['admin', 'supercoach', 'coach'];
  protected showExportDialog = false;
  protected selectedRows: any = [];
  protected startDate: number | null = null;
  protected endDate: number | null = null;
  protected exportTypes: string[] = [];
  protected exportIsLoading = false;
  protected exportError = '';
  protected exportDayPart = '';

  protected mounted() {
    this.setUserType();
  }

  get userType(): string {
    return this.$store.state.userType;
  }

  get userId(): string {
    return this.$store.state.userId;
  }

  protected isSelectable() {
    return this.userOverviewType === 'participant' && this.allowedExport.includes(this.userType);
  }

  protected setUserType() {
    this.userOverviewType = this.$route.meta?.usersType;
    this.tableHeaders = tableHeaders[this.userOverviewType];
    this.setUserTypeForm();
    this.listUsers();

    if (this.userOverviewType === 'participant') {
      this.tableFilters = [
        {
          name: 'company',
          label: 'Bedrijf',
          items: [],
          value: null,
        },
        {
          name: 'department',
          label: 'afdeling',
          items: [],
          value: null,
        },
      ];
    } else {
      this.tableFilters = [];
    }
  }

  protected setUserTypeForm() {
    switch (this.userOverviewType) {
      case 'supercoach':
        this.form = new SuperCoachForm();
        break;
      case 'coach':
        this.form = new CoachForm();
        break;
      case 'participant':
        this.form = new ParticipantForm();
        break;
      default:
        break;
    }
  }

  // fetch users based on userType and params
  protected listUsers() {
    const params: RequestParams = {};

    this.activeFilters = [];
    this.tableFilters.forEach((filter: Filter) => {
      if (filter.value) {
        this.activeFilters.push(
          {
            name: filter.name,
            value: typeof filter.value === 'object' ? filter.value.name : filter.value,
          },
        );
      }
    });

    if (this.activeFilters) {
      this.activeFilters.forEach((filter: RequestFilter) => {
        params[filter.name] = filter.value;
      });
    }

    if (this.searchQuery) {
      params.search = this.searchQuery;
    }

    getUsers(this.userOverviewType, params)
    .then((response: any) => {
      if (response.data.users) {
        this.tableRows = response.data.users;
      }

      if (response.data.companies) {
        response.data.companies.forEach((item: any) => {
          this.tableFilters[0].items.push({
            name: item.company,
            label: item.company,
            subItems: item.department.filter((department: string) => department),
          });
        });
      }
    })
    .catch((error: any) => {
      console.log('error', error);
    });
  }

  protected getExport() {
    const payload: any = {
      users: [],
      isSingle: this.selectedRows.length  < 2,
    };

    this.selectedRows.forEach((selectedRow: any) => {
      if (selectedRow._id) payload.users.push(selectedRow._id);
    });

    this.exportError = '';
    this.exportIsLoading = true;
    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/admin/v1/export?startDate=${this.startDate}&endDate=${this.endDate}${this.exportDayPart.length ? '&dayPart=' + this.exportDayPart : ''}`,
      false,
      true,
      payload,
    ).then(
      (response: any) => {
        this.exportIsLoading = false;
        if (response.data.length) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export.csv');
          document.body.appendChild(link);
          link.click();
          this.showExportDialog = false;
        } else {
          this.exportError = response.data.status;
        }
      },
      (error: any) => {
        this.exportIsLoading = false;
        this.exportError = 'Er is iets mis gegaan';
        console.log('export error', error);
      },
    );
  }

  protected exportDialogResponse(response: any) {
    if (!response.confirmation) {
      this.exportError = '';
      this.showExportDialog = false;
      return;
    }

    if (response.startDate) this.startDate = response.startDate;
    if (response.endDate) this.endDate = response.endDate;
    this.exportDayPart = response.dayPart;
    if (response.measurmentTypes) this.exportTypes = response.measurmentTypes;
    this.getExport();
  }

  protected createOrPatch(payload: any, isEditting: boolean) {
    this.formResponseLoading = true;
    createOrPatchUser(payload, isEditting ? this.editingId : null)
    .then((response: any) => {
      console.log('response', response);
      this.formResponseLoading = false;
      this.formResponse = {
        success: true,
        message: isEditting ? 'De gebruiker is aangepast.' : 'De gebruiker is aangemaakt.',
      };
      this.listUsers();
    })
    .catch((error: any) => {
      this.formResponseLoading = false;
      this.formResponse = {
        success: error.response.data.success,
        message: error.response.data.error ? error.response.data.error : 'Er is iets fout gegaan',
      };
      console.log('error', error);
    });
  }

  protected viewDetail(userId: string) {
    this.$router.push(`${this.$route.name}/${userId}`);
  }

  protected searchUsers(query: string) {
    this.searchQuery = query;
    this.listUsers();
  }

  protected filterSelected() {
    this.tableFilters.forEach((filter: Filter) => {
      if (filter.name === 'company') {

        // if selected company has subItems add them to department array
        if (filter.value && filter.value.subItems) {
          this.tableFilters[1].items = filter.value.subItems;
        } else {
          this.tableFilters[1].items = [];
        }

        // if selected company has no match with currently selected department, clear selected department
        if (this.tableFilters[1].value) {
          if (!this.tableFilters[1].items.includes(this.tableFilters[1].value)) {
            this.tableFilters[1].value = null;
          }
        }
      }
    });
    this.listUsers();
  }

  protected createHandler() {
    this.isEditing = false;
    this.formDialog = true;
  }

  protected selectedRowsHandler(selected: any) {
    this.selectedRows = selected;
  }

  // respond to table action trigger
  protected tableActionHandler(event: Event, item: any) {
    if (item.actionType === 'view') this.viewDetail(item._id);
    else if (item.actionType === 'delete') {
      const deleteBodyTexts = {
        admin: 'Weet u zeker dat u deze admin wilt verwijderen? De coaches/deelnemers onder deze gebruiker hebben dan geen coach meer.',
        supercoach: 'Weet u zeker dat u deze super coach wilt verwijderen? De coaches/deelnemers onder deze gebruiker hebben dan geen super coach meer.',
        coach: 'Weet u zeker dat u deze coach wilt verwijderen? De deelnemers onder deze gebruiker hebben dan geen coach meer.',
        participant: 'Weet u zeker dat u deze deelnemer wilt verwijderen?',
      };
      this.confirmDialogText = {
        title: `${this.userTypeDisplayName[this.userOverviewType].single} verwijderen`,
        body: deleteBodyTexts[this.userOverviewType],
        confirm: `${this.userTypeDisplayName[this.userOverviewType].single} verwijderen`,
      };
      this.editingId = item._id;
      this.confirmDialog = true;
    } else if (item.actionType === 'edit') {
      this.editingId = item._id;
      this.isEditing = true;
      if (this.form) this.form.fillValues(item);
      this.formDialog = true;
    }
  }

  protected clearEditCreateForm() {
    this.formDialog = false;
    this.setUserTypeForm();
    this.formResponse = null;
  }

  protected formDialogResponse(confirmed: 'send' | 'close', isEditting: boolean) {
    if (confirmed === 'close') {
      this.clearEditCreateForm();
      return;
    }

    const requestPayload: RequestPayload = {};
    if (this.form)
    this.form.form.forEach((input: any) => {
      if (this.isEditing && input.create) return;
      if (input.input.name === 'myCoach' && this.userType === 'coach') {
        requestPayload[input.input.name] = this.userId;
        return;
      }
      requestPayload[input.input.name] = input.input.value;
    });
    if (!isEditting) requestPayload.userRole = this.userOverviewType;
    this.createOrPatch(requestPayload, isEditting);
  }

  protected confirmDialogResponse(event: Event, confirmed: boolean) {
    if (!confirmed) {
      this.confirmDialog = false;
      return;
    }
    deleteUser(this.editingId)
    .then((response: any) => {
      this.confirmDialog = false;
      this.listUsers();
    })
    .catch((error: any) => {
      this.confirmDialog = false;
      console.log('error', error);
    });
  }
}

type RequestParams = {
  [key: string]: string | number;
};

type RequestPayload = {
  [key: string]: string | number;
};

type FormResponse = {
  success: boolean;
  message: string;
};
