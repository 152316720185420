import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import Theme from '@/config/theme';
import { TableRow } from '@/models/Datatable';

@Component({
  extends: Bar,
})
export default class MeasurementsChart extends Vue {
  @Prop()
  protected chartData!: any;

  @Prop()
  protected chartType?: string;

  @Prop({default: false})
  protected rrChart?: boolean;

  @Prop({default: false})
  protected allowClick?: boolean;

  @Prop()
  protected measurements?: TableRow[];

  protected chartIndex?: number;

  protected options = {
    onClick: (event: Event, array: any) => {
      if (array.length != 0) {
        this.onBarClick(array[0]._index);
      }
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    scales: {
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          suggestedMax: 100,
        },
        scaleLabel: {
          display: true,
          labelString: 'HR / HRV / Readiness',
        },
      }],
      xAxes: [{
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
        scaleLabel: {
          display: this.chartData.type === 'detail' ? true : false,
          labelString: 'Tijd in minuten',
        },
      }],
    },
  };
  protected mounted() {
    if (this.chartType === 'detail') this.createDetailChart();
    else this.createChart();
  }

  protected createChart() {
    this.chartIndex = [this.chartData.data.readiness.findIndex((r: number) => r != null),
    this.chartData.data.hr.findIndex((r: number) => r != null),
    this.chartData.data.hrv.findIndex((r: number) => r != null)].reduce((a, b) => Math.min(a, b));

    console.log([...this.chartData.labels].slice(this.chartIndex));
    // @ts-ignore
    this.renderChart({
      labels: [...this.chartData.labels].slice(this.chartIndex),
      datasets: [
        {
          type: 'line',
          yAxisID: 'A',
          label: 'Readiness = %',
          data: [...this.chartData.data.readiness].slice(this.chartIndex),
          borderColor: Theme.primaryGreen,
          fill: false,
        },
        {
          type: this.chartData.type === 'detail' ? 'line' : 'bar',
          yAxisID: 'A',
          label: 'HR = Beats per minute',
          data: [...this.chartData.data.hr].slice(this.chartIndex),
          borderColor: Theme.primaryOrange,
          backgroundColor: Theme.primaryOrange,
          fill: false,
        },
        {
          type: this.chartData.type === 'detail' ? 'line' : 'bar',
          yAxisID: 'A',
          label: 'HRV = Milliseconds',
          data: [...this.chartData.data.hrv].slice(this.chartIndex),
          borderColor: Theme.primaryBlue,
          backgroundColor: Theme.primaryBlue,
          fill: false,
        },
      ],
    }, this.options);
  }

  protected createDetailChart() {
    // @ts-ignore
    this.renderChart({
      labels: this.chartData.labels,
      datasets: [
        {
          type: this.chartData.type === 'detail' ? 'line' : 'bar',
          yAxisID: 'A',
          label: this.rrChart ? 'RR' : 'HR = Beats per minute',
          data: this.rrChart ? this.chartData.data.rr : this.chartData.data.hr,
          borderColor: Theme.primaryOrange,
          backgroundColor: Theme.primaryOrange,
          fill: false,
        },
      ],
    }, this.options);
  }

  protected onBarClick(index: number) {
    if (!this.allowClick) return;
    const measureDate = [...this.chartData.labels].slice(this.chartIndex)[index];
    this.$emit('viewDetail', this.measurements?.find((m) => m.createdAt.indexOf(measureDate) > -1)?.measurementID);
  }

  @Watch('chartData', {deep: true})
  protected chartDataWatch(to: any) {
    if (this.chartType === 'detail') this.createDetailChart();
    else this.createChart();
  }
}

