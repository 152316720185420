import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<UploadDialog>({
  components: {
    //
  },
})
export default class UploadDialog extends Vue {
  @Prop({default: false})
  protected showDialog!: boolean;

  protected disableButton = true;
  protected imageUrl: any = null;
  protected imageTooLarge = true;
  protected formData = new FormData();

  protected fileChange(file: File) {
    if (!file) {
      this.imageUrl = null;
      this.disableButton = true;
      return;
    }

    const sizeInMB: any = (file.size / (1024 * 1024)).toFixed(2);
    if (sizeInMB > 1) {
      this.imageTooLarge = true;
      this.disableButton = true;
      return;
    } else {
      this.imageTooLarge = false;
    }
    this.disableButton = false;
    this.formData.append('avatar', file);
    if (file) this.imageUrl = URL.createObjectURL(file);
    else this.imageUrl = '';
  }

  protected checkFileSize() {
    if (this.imageTooLarge) {
      return 'Dit bestand is te groot';
    } else return true;
  }

  protected confirm(confirmation: boolean) {
    this.$emit('confirmResponse', event, confirmation, this.formData);
  }
}
