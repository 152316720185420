import { Vue, Component, Watch } from 'vue-property-decorator';
import Navigation from '@/components/Navigation/Navigation.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import { request } from '@/services/request';

@Component<App>({
  components: {
    Navigation,
    Breadcrumbs,
  },
})
export default class App extends Vue {
  protected drawer = null;
  protected publicRoutes = ['login', 'reset password'];

  protected mounted() {
    if (this.$route.name === 'login') return;
    if (!this.publicRoutes.includes((this.$route.name as string))) {
      this.tryLogin();
    }
  }

  protected redirectToRoot() {
    switch (this.$store.state.userType) {
      case 'admin':
        this.$router.replace('/supercoaches');
        break;
      case 'supercoach':
        this.$router.replace('/coaches');
        break;
      case 'coach':
        this.$router.replace('/deelnemers');
        break;
      case 'participant':
        this.$router.replace('/metingen');
        break;
      default:
        break;
    }
  }

  protected tryLogin() {
    if (!localStorage.getItem('userToken')) {
      this.$router.replace('/login');
      return;
    }

    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/auth/v1/login`,
      false,
      false,
      {},
      ).then(
      (response: any) => {
        if (response.data.userType) {
          this.$store.dispatch('setUserType', response.data.userType);
        }

        if (response.data.fullName) {
          this.$store.dispatch('setUser', response.data.fullName);
        }

        if (response.data.userID) {
          this.$store.dispatch('setUserId', response.data.userID);
        }

        if (response.data.token) {
          this.$store.dispatch('setToken', response.data.token);
          localStorage.setItem('userToken', response.data.token);
        }

        if (response.data.avatar) {
          this.$store.dispatch('setUserHasAvatar', response.data.avatar);
        } else {
          this.$store.dispatch('setUserHasAvatar', false);
        }

        if (this.$route.path === '/') {
          this.redirectToRoot();
        }
      },
      (error: any) => {
        this.$router.replace('/login');
      },
    );
  }

  protected logout() {
    localStorage.removeItem('userToken');
    this.$router.replace('/login');
  }

  get showNavigation(): boolean {
    if (this.$route.name === 'login') return false;
    if (this.$route.name === 'reset password') return false;
    return true;
  }

  @Watch('$route', {deep: true})
  protected routeWatch(to: any) {
    if (this.$route.path === '/') {
      this.redirectToRoot();
    }
  }

}
