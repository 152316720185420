import { render, staticRenderFns } from "./ConfirmDialog.vue?vue&type=template&id=54f8c38b"
import script from "@/components/dialogs/ConfirmDialog/ConfirmDialog.ts?vue&type=script&lang=ts&external"
export * from "@/components/dialogs/ConfirmDialog/ConfirmDialog.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports