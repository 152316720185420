import { Vue, Component} from 'vue-property-decorator';
import MeasurementChart from '@/components/charts/MeasurementChart/MeasurementChart';
import { request } from '@/services/request';

@Component<MeasurementDetail>({
  components: {
    MeasurementChart,
  },
})
export default class MeasurementDetail extends Vue {
  protected currentMeasurementId = '';
  protected currentMeasurement: any = null;
  protected showDetail = true;
  protected currentHrv: number | null = null;
  protected currentReadiness: number | null = null;
  protected createTimestamp: string | null = null;
  protected chartDataDetail: any = {
    labels: [],
    data: {
      hr: [],
      rr: [],
    },
  };
  protected selectedChartFilterPeriod = 'day';
  protected chartFilterPeriodItems = [
    {
      label: 'Dag',
      value: 'day',
    }, {
      label: 'Week',
      value: 'week',
    }, {
      label: 'Maand',
      value: 'month',
    },
  ];

  protected mounted() {
    this.currentMeasurementId = this.$route.params.measurementId;
    this.getMeasurement();
  }

  protected allowExport() {
    const allowed = ['admin', 'supercoach', 'coach'];
    if (allowed.includes(this.userType)) return true;
    return false;
  }

  protected exportMeasurement() {
    if (!this.allowExport) return;

    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/export?id=${this.currentMeasurementId}`,
      false,
      false,
      {},
      ).then(
      (response: any) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.$store.state.user ? `Export ${this.$store.state.user}.csv` : 'export.csv');
          document.body.appendChild(link);
          link.click();
        }
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  protected getMeasurement() {
    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/measurement/${this.currentMeasurementId}`,
      false,
      false,
      {},
      ).then(
      (response: any) => {
        if (response.data) {
          this.currentMeasurement = response.data.measurement;
          this.chartDataDetail = response.data.measurement;
          this.createTimestamp = response.data.measurement.create_timestamp;

          if (response.data.measurement.data.hrv) this.currentHrv = response.data.measurement.data.hrv;
          if (response.data.measurement.data.readiness) {
            this.currentReadiness = response.data.measurement.data.readiness;
          }

          this.chartDataDetail.type = 'detail';
        }
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  get measurementType() {
    return this.$route.query.measurementType;
  }

  get userType(): string {
    return this.$store.state.userType;
  }

  protected viewDetailChart() {
    this.showDetail = true;
  }
}

