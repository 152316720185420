import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<ProfileDetails>({
  components: {
    //
  },
})
export default class ProfileDetails extends Vue {
  @Prop({ default: require('@/assets/images/profile-avatar.jpeg') })
  protected profileImage?: string;

  @Prop({default: false})
  protected allowEditAvatar?: boolean;

  @Prop({ default: () => {
    return [
      { text: 'John Doe', icon: 'mdi-account' },
      { text: 'test@email.nl', icon: 'mdi-at' },
      { text: 'Admin', icon: 'mdi-flag' },
    ];
  } })
  protected profileItems?: any;

  protected clickEvent(eventType: string) {
    this.$emit('clickEvent', event, eventType);
  }
}

