export class ParticipantForm {
  public form = [
    {
      cols: '12',
      sm: '6',
      md: '4',
      input: {
        name: 'firstName',
        type: 'text',
        label: 'Voornaam*',
        required: true,
        validate: 'name',
        valid: false,
        value: '',
      },
    },
    {
      cols: '12',
      sm: '6',
      md: '4',
      input: {
        name: 'middleName',
        type: 'text',
        label: 'Tussenvoegsel',
        validate: 'name',
        valid: true,
        value: '',
      },
    },
    {
      cols: '12',
      sm: '6',
      md: '4',
      input: {
        name: 'lastName',
        type: 'text',
        label: 'Achternaam*',
        required: true,
        validate: 'name',
        valid: false,
        value: '',
      },
    },
    {
      cols: '12',
      input: {
        name: 'email',
        type: 'text',
        label: 'E-mail*',
        required: true,
        validate: 'email',
        valid: false,
        value: '',
      },
    },
    {
      cols: '12',
      input: {
        name: 'phone',
        type: 'text',
        label: 'Telefoonnummer*',
        required: true,
        validate: 'number',
        valid: false,
        value: '',
      },
    },
    {
      cols: '12',
      md: '6',
      input: {
        name: 'company',
        type: 'text',
        label: 'Bedrijfsnaam',
        // required: true,
        validate: 'name',
        valid: false,
        value: '',
      },
    },
    {
      cols: '12',
      md: '6',
      input: {
        name: 'department',
        type: 'text',
        label: 'Afdeling',
        validate: 'name',
        // required: true,
        valid: false,
        value: '',
      },
    },
    {
      cols: '12',
      create: true,
      allowedUserTypes: ['admin', 'supercoach'],
      input: {
        name: 'myCoach',
        get: 'allCoaches',
        type: 'autocomplete',
        label: 'Toegewezen coach*',
        value: '',
        icon: 'mdi-account',
        items: [],
        required: true,
        validate: '',
        valid: true,
      },
    },
    {
      cols: '12',
      input: {
        name: 'language',
        type: 'radio',
        label: 'Taal',
        required: true,
        value: 'EN',
        items: [
          {
            label: 'Nederlands',
            value: 'NL',
          },
          {
            label: 'Engels',
            value: 'EN',
          },
        ],
      },
    },
  ];

  public fillValues(values: any) {
    this.form.forEach((input: any) => {
      if (values[input.input.name]) {
        input.input.value = values[input.input.name];
      }
    });
  }
}
