import { render, staticRenderFns } from "./ProfileAction.vue?vue&type=template&id=684eb4c9&scoped=true"
import script from "@/components/ProfileAction/ProfileAction.ts?vue&type=script&lang=ts&external"
export * from "@/components/ProfileAction/ProfileAction.ts?vue&type=script&lang=ts&external"
import style0 from "@/components/ProfileAction/ProfileAction.scss?vue&type=style&index=0&id=684eb4c9&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684eb4c9",
  null
  
)

export default component.exports