import { Vue, Component } from 'vue-property-decorator';
import { request } from '@/services/request';
// @ts-ignore


@Component<Login>({
  
})
export default class Login extends Vue {
  protected email = '';
  protected password = '';
  protected errorMessage = '';
  protected requestPassword = false;
  protected passwordRequested = false;
  protected showPassword = false;
  protected userDetail: any = {};
  protected token = '';
  protected show2FA = false;
  protected recover2FA = false;
  protected recoveryToken = '';
  protected recoveryCode = '';

  public mounted() {

    //
  }



  protected copyToClipboard() {
    navigator.clipboard.writeText(this.recoveryCode);

  }

  protected newPasswordRequested() {
    this.requestPassword = false;
    this.passwordRequested = true;
  }

  protected forgotPasswordHandler() {
    this.requestPassword = true;
    this.errorMessage = '';
  }

  protected emailIsValid(email: string) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (pattern.test(email)) return true;
    else return false;
  }
  protected handleRecover() {
    this.recover2FA = true;
  }
  protected onDone() {
    this.$router.replace('/');
  }
  protected recover2Fa() {
    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/admin/v1/user/recover-2fa`,
      false,
      true,
      { code: this.recoveryToken },
      this.userDetail.token,
    ).then(
      (response: any) => {
        if (response.data) {
          this.userDetail = response.data;
          this.show2FA = true;
          this.recover2FA = false;
        }
      },
      (error: any) => {
        if (error.response.data.error) {
          this.errorMessage = error.response.data.error;
        }
      },
    );

  }

  protected requestPasswordChange() {
    console.log('EMAIL VALID:', this.emailIsValid(this.email));
    if (!this.emailIsValid(this.email)) {
      this.errorMessage = 'Vul een geldig e-mailadres in';
      return;
    } else this.errorMessage = '';

    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/global/v1/forgotPassword`,
      false,
      true,
      {
        email: this.email,
      },
    ).then(
      (response: any) => {
        this.newPasswordRequested();
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }
  protected verifyOtp() {
    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/admin/v1/user/verify-2fa`,
      false,
      true,
      { token: this.token },
      this.userDetail.token,
    ).then(
      (response: any) => {
        if (response.data) {
          if (this.userDetail?.userType) {
            this.$store.dispatch('setUserType', this.userDetail.userType);
          }

          if (this.userDetail.fullName) {
            this.$store.dispatch('setUser', this.userDetail.fullName);
          }

          if (this.userDetail.userID) {
            this.$store.dispatch('setUserId', this.userDetail.userID);
          }

          if (this.userDetail.token) {
            this.$store.dispatch('setToken', this.userDetail.token);
            localStorage.setItem('userToken', this.userDetail.token);
          }

          if (this.userDetail.avatar) {
            this.$store.dispatch('setUserHasAvatar', this.userDetail.avatar);
          } else {
            this.$store.dispatch('setUserHasAvatar', false);
          }
          if (response.data.code) {
            this.recoveryCode = response.data.code;
          } else {
            this.$router.replace('/');
          }
        }

      },
      (error: any) => {
        if (error.response.data.error) {
          this.errorMessage = error.response.data.error;
          // @ts-ignore
          this.$refs.otpInput?.clearInput();

        }
      },
    );
  }

  protected handleOnChange(value: string) {
    this.token = value;
  }

  protected login() {
    if (!this.email && !this.password) {
      this.errorMessage = 'Vul een e-mailadres en wachtwoord in';
      return;
    }

    const payload = {
      email: this.email,
      password: this.password,
    };

    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/auth/v1/login`,
      false,
      false,
      payload,
    ).then(
      (response: any) => {
        if (response.data) {
          this.userDetail = response.data;
          this.show2FA = true;
        }

      },
      (error: any) => {
        if (error.response.data.error) {
          this.errorMessage = error.response.data.error;
        }
      },
    );
  }
}
