import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import moment from 'moment';

// components
import DatePicker from '@/components/DatePicker/DatePicker.vue';

@Component<ExportDialog>({
  components: {
    DatePicker,
  },
})
export default class ExportDialog extends Vue {
  @Prop({default: false})
  protected showDialog!: boolean;

  @Prop({default: false})
  protected isLoading?: boolean;

  @Prop()
  protected dialogTitle!: string;

  @Prop()
  protected exportError?: string;

  @Prop({ default: () => {
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      password: '',
    };
  } })
  protected prefill?: any;

  protected measurmentTypes = ['rest'];
  protected startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
  protected endDate = moment(new Date()).format('YYYY-MM-DD');

  protected selectedDayPart = '';
  protected dayParts = [
    {
      name: 'morning',
      label: 'Ochtend',
    },
    {
      name: 'afternoon',
      label: 'Middag',
    },
    {
      name: 'evening',
      label: 'Avond',
    },
  ];

  protected setStartDate(date: string) {
    this.startDate = date;
  }

  protected setEndDate(date: string) {
    this.endDate = date;
  }

  protected confirm(confirmed: boolean) {
    // if (!confirmed) {
    //   (this.$refs.form as Vue & { reset: () => boolean }).reset();
    // }

    this.$emit('dialogResponse', {
      confirmation: confirmed,
      startDate: moment(this.startDate).unix(),
      endDate: moment(this.endDate).add(1, 'days').unix(),
      dayPart: this.selectedDayPart ? this.selectedDayPart : '',
      measurmentTypes: this.measurmentTypes,
    });
  }
}
