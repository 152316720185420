import { Vue, Component} from 'vue-property-decorator';
import moment from 'moment';
import Datatable from '@/components/Datatable/Datatable.vue';
import { TableHeader, TableRow } from '@/models/Datatable';
import MeasurementChart from '@/components/charts/MeasurementChart/MeasurementChart';
import { request } from '@/services/request';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import { measurementTable } from '@/constants/tableItems';

// types
import { Filter, RequestFilter } from '@/models/Filter';

// settings
import { chartData, chartFilterPeriodItems } from '@/constants/chart';

@Component<Measurement>({
  components: {
    Datatable,
    MeasurementChart,
    DatePicker,
  },
})
export default class Measurement extends Vue {
  protected selectedChartFilterPeriod = 'day';
  protected startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
  protected endDate = moment(new Date()).format('YYYY-MM-DD');
  protected activeFilters: RequestFilter[] = [];
  protected chartFilterPeriodItems = chartFilterPeriodItems;
  protected chartData: any = chartData;
  protected tableFilters: any = measurementTable.filters;
  protected tableHeaders: TableHeader[] = measurementTable.headers;
  protected tableRows: TableRow = [];

  protected mounted() {
    this.getMeasurements();
    this.getMeasurementsStatistics();
    // this.getMeasurementsStatistics('week');
    // this.getMeasurementsStatistics('month');
  }

  protected getMeasurements() {
    const params: any = {
      type: 'rest',
      page: 1,
      limit: 200,
    };

    this.activeFilters = [];
    this.tableFilters.forEach((filter: Filter) => {
      if (filter.value) {
        this.activeFilters.push(
          {
            name: filter.name,
            value: typeof filter.value === 'object' ? filter.value.name : filter.value,
          },
        );
      }
    });

    if (this.activeFilters) {
      this.activeFilters.forEach((filter: RequestFilter) => {
        params[filter.name] = filter.value;
      });
    }

    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/measurements/${this.$store.state.userId}`,
      false,
      false,
      params,
      ).then(
      (response: any) => {
        if (response.data.measurements) {
          this.tableRows = response.data.measurements;
        } else this.tableRows = [];
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  protected getMeasurementsStatistics() {
    const params = {
      type: 'rest',
      startDate: moment(this.startDate).unix(),
      endDate: moment(this.endDate).add(1, 'days').unix(),
    };

    request(
      'GET',
      `${process.env.VUE_APP_API_URL}/admin/v1/measurements/${this.$store.state.userId}/getStatistics`,
      false,
      false,
      params,
      ).then(
      (response: any) => {
        if (response.data.measurements) {
          this.chartData = response.data.measurements;
        }
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  get userType(): string {
    return this.$store.state.userType;
  }

  protected setStartDate(date: string) {
    this.startDate = date;
    this.getMeasurementsStatistics();
  }

  protected setEndDate(date: string) {
    this.endDate = date;
    this.getMeasurementsStatistics();
  }

  protected filterSelected() {
    this.getMeasurements();
  }

  protected viewDetail(id: string, measurementType: string) {
    this.$router.push({ path: `/metingen/${id}`, query: { measurementType } });
  }

  protected chartActionHandler(id: string, measurementType: string) {
    this.viewDetail(id, 'rest');
  }

  protected actionHandler(event: Event, item: any) {
    if (item.actionType === 'viewMeasurement') this.viewDetail(item.measurementID, item.type);
  }

  protected createDummyMeasurement() {
    const a = moment('2020-06-25');
    const b = moment('2020-06-27');

    for (const m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
      this.generateDummyData(m.unix());
    }
    console.log('Dummy data generated');
  }

  protected generateDummyData(date: any) {
    const hrData: any = [];
    const rrData: any = [];
    for ( let i = 0; i < 360; i++ ) {
      hrData.push( Math.floor(Math.random() * (110 - 55 + 1) + 55) );
      rrData.push( Math.floor(Math.random() * (800 - 700 + 1) + 700) );
    }
    this.saveDummyMeasurement(hrData, rrData, date);
  }

  protected saveDummyMeasurement(hrData: any, rrData: any, date: any) {
    const payload: any = {
      measurements: {
        measurementType: 'rest',
        duration: 6,
        hrData,
        rrData,
        dateSpecifications: {
          fullDate: date,
        },
      },
    };

    request(
      'POST',
      `${process.env.VUE_APP_API_URL}/global/v1/addMeasurement`,
      false,
      true,
      payload,
    ).then(
      (response: any) => {
        console.log('measurement created', response);
      },
      (error: any) => {
        console.log('measurement error', error);
      },
    );
  }
}

