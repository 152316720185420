export const chartData = {
  day: {
    labels: [],
    data: {
      hr: [],
      hrv: [],
      readiness: [],
    },
  },
  week: {
    labels: [],
    data: {
      hr: [],
      hrv: [],
      readiness: [],
    },
  },
  month: {
    labels: [],
    data: {
      hr: [],
      hrv: [],
      readiness: [],
    },
  },
};

export const chartFilterPeriodItems = [
  {
    label: 'Dag',
    value: 'day',
  }, {
    label: 'Week',
    value: 'week',
  }, {
    label: 'Maand',
    value: 'month',
  },
];
