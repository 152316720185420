import { Vue, Component } from 'vue-property-decorator';
import { request } from '@/services/request';

// services
import { checkPasswordStrength } from '@/services/passwordStrength';

@Component<ResetPassword>({
  components: {
    //
  },
})
export default class ResetPassword extends Vue {
  protected newPassword = '';
  protected newPasswordConfirmation = '';
  protected errorMessage = '';
  protected resetSuccessful = false;
  protected resetToken: any = '';
  protected showPassword = false;
  protected showPasswordTwo = false;
  protected validationErrorMessages = {
    length: 'Het wachtwoord moet minimaal 8 karakters bevatten',
    lowercase: 'Het wachtwoord mist een kleine letter',
    uppercase: 'Het wachtwoord mist een hoofdletter',
    numeric: 'Het wachtwoord mist een nummer',
    special: 'Het wachtwoord mist een speciaal karakter (!@#$%^&*)',
  };
  protected validationErrorKeys: string[] = [];

  public mounted() {
    if (this.$route.query && this.$route.query.token) {
      this.resetToken = this.$route.query.token;
    } else {
      this.$router.replace('/login');
    }
  }

  protected resetPassword() {
    if (!this.resetToken) return;

    request(
      'PUT',
      `${process.env.VUE_APP_API_URL}/global/v1/resetPassword/${this.resetToken}`,
      false,
      false,
      {
        password: this.newPassword,
      },
      ).then(
      (response: any) => {
        console.log('response');
        this.resetSuccessful = true;
      },
      (error: any) => {
        console.log('error', error);
      },
    );
  }

  protected validatePassword() {
    const failedArray = checkPasswordStrength(this.newPassword);

    if (this.newPassword !== this.newPasswordConfirmation) {
      this.errorMessage = 'Wachtwoorden komen niet overeen';
      return false;
    }
    this.errorMessage = '';

    if (failedArray.length) {
      this.validationErrorKeys = failedArray;
      return false;
    }

    this.validationErrorKeys = [];
    return true;
  }

  protected save() {
    if (!this.validatePassword()) {
      return;
    }

    this.resetPassword();
  }

  protected toLogin() {
    this.$router.replace('/login');
  }

}
